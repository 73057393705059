<template>
    <div data-app>
      <div class="card card-custom h-100">
  
        <template v-if="this.sdata.action == 'perbaikan'">
          <template v-if="loading == 'pengajuan'">
            <v-card>
                <v-card-title class="headline">Ajukan data</v-card-title>
      
                <v-card-text>
                    Apakah anda yakin akan mengajukan perbaikan data ?
                </v-card-text>
      
                <v-card-actions>
                <v-spacer></v-spacer>
      
                <b-button
                variant="link-success"
                class="m-3"
                @click="closeDialog">
                    BATAL
                </b-button>
      
                <b-button
                variant="link-danger"
                ref="kt_submit"
                class="m-3"
                @click="onSubmit">
                    AJUKAN
                </b-button>
                </v-card-actions>
            </v-card>
          </template>
          <template v-else-if="loading == 'form'">
            <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
          </template>
          <template v-else>
            <div class="card-body">
              <FacebookLoader></FacebookLoader>
            </div>
            <div class="card-footer">
              <button
                @click="closeDialog"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Close
              </button>
            </div>
          </template>
        </template>
        <template v-if="this.sdata.action == 'pembatalan'">
          <v-card>
              <v-card-title class="headline">Hapus Perbaikan</v-card-title>
      
              <v-card-text>
                  Apakah anda yakin akan membatalkan pengajuan perbaikan data ?
              </v-card-text>
      
              <v-card-actions>
              <v-spacer></v-spacer>
      
              <b-button
              variant="link-success"
              class="m-3"
              @click="closeDialog">
                  BATAL
              </b-button>
      
              <b-button
              variant="link-danger"
              class="m-3"
              @click="onDelete">
                  HAPUS
              </b-button>
              </v-card-actions>
          </v-card>
        </template>
        
      </div>
    </div>
  </template>
  
  <script>
  import DistribusiService from "@/core/services/api/nasional/distribusi.service";
  import ErrorService from "@/core/services/error.service";
  import { FacebookLoader } from 'vue-content-loader'

  import FormEditSendDoc from "@/view/pages/nasional/distribusi/form/send-doc/_Edit.vue";
  export default {
    name: "unggas-nasional-data-distribusi-ajukan-perbaikan",
    props: {
        sdata: {
            required: true
        }
    },
    components: {
      FacebookLoader,
      FormEditSendDoc,
    },  
    data() {
      return {
        loading: 'loading',
        dataDispaly: ['send-doc'],
        curComponent: null,
        sdataForm: null,
        show: true,
      }
    },
    methods: {
      closeDialog(refresh = false) {
          return this.$emit('close-modal', {status: true, refresh})
      },
      removeSpinner(button) { 
        button.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      },
      openDialogForm() {
        if(!this.dataDispaly.includes(this.sdata.kategori)) return this.closeDialog()

        this.sdataForm = this.sdata
        this.sdataForm.action = 'perbaikan'

        if(this.sdata.kategori == 'send-doc') this.curComponent = "FormEditSendDoc"
        
        this.loading = 'form'
      },
      closeDialogForm(res) {
        if (res.status) {
          if (res.refresh) {
              return this.closeDialog(true)    
          }
          this.closeDialog()    
        }
      },
      getData () {
        DistribusiService.getAjukanPerbaikan({
          _id: this.sdata._id,
          kategori: this.sdata.kategori,
        })
        .then((res) => {
          if (res.data.status) {
            const menu = res.data.data
            if(menu == 'form') {
              this.openDialogForm()
            }
            this.loading = menu
            return;
          }
          ErrorService.message(res.data)
          this.closeDialog()
        })
        .catch((err) => {
          ErrorService.status(err)
          this.closeDialog()
        })
      },
      onSubmit(evt) {
        evt.preventDefault()
  
          // set spinner to submit button
          const submitButton = this.$refs["kt_submit"];
          submitButton.classList.add("spinner", "spinner-light", "spinner-right");
  
          // dummy delay
          // setTimeout(() => {
                DistribusiService.addAjukanPerbaikan({
                  _id: this.sdata._id,
                  kategori: this.sdata.kategori,
                })
                  .then((res) => {
                      this.removeSpinner(submitButton)
                      ErrorService.message(res.data)
                      
                      if (res.data.status) {
                        this.openDialogForm()
                      }
                      return;
                  })
                  .catch((err) => {
                    this.removeSpinner(submitButton)
                    ErrorService.status(err)
                  })
  
          // }, 1000);
      },
      onDelete () {
        DistribusiService.deleteAjukanPerbaikan({
          _id: this.sdata._id,
          kategori: this.sdata.kategori,
        })
        .then((res) => {
            if (res.data.status) { 
              this.closeDialog(true)  
            }
            ErrorService.message(res.data)
            return
        })
        .catch((err) => {
            return ErrorService.status(err)
        })
      },
    },
    mounted() {
      if (this.sdata.action == 'perbaikan') this.getData();
    },
  };
  </script>
  