<template>
  <div data-app>
    
    <div class="card card-custom h-100">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Edit Ditribusi
          </h3>
        </div>
      </div>

      <!--begin::Form-->
        <div class="card-body">
        <perfect-scrollbar
        class="scroll"
        style="max-height: 98%; position: relative;"
        >
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">

            <validationProvider name="Int/Eks" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group-1" 
                label="Int/Eks:" 
                label-for="input-1"
                :invalid-feedback="errors[0]"
                :state="valid">
                <b-form-select2
                id="input-1"
                v-model="form.kategori"
                :options="opsKategoriDistribusi"
                required
                variant="solid"
                :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>

            <validationProvider name="Strain" rules="required" v-slot="{ errors, valid }">
              <b-form-group
                id="input-group25" 
                label="Strain:" 
                label-for="input-2"
                :invalid-feedback="errors[0]"
                :state="valid">
                <b-form-select2
                id="input-2"
                v-model="form.strain"
                :options="opsStrain"
                required
                variant="solid"
                :state="valid"
                ></b-form-select2>
              </b-form-group>
            </validationProvider>

            <b-row class="w-100 mx-0" v-for="(rinc, index) in form.rincian" :key="index">
                <b-col cols="10" class="pt-5 px-2 pb-0">
                    <h6 class="text-muted">Rinc. {{index + 1}}</h6>
                </b-col>
                <b-col cols="2" class="text-right pt-5 px-2 pb-0">
                    <b-button v-show="(form.rincian.length > 1) ? true : false" @click="deleteRincian(index)" size="sm" variant="link" class="text-hover-primary">
                    <i class="flaticon2-rubbish-bin-delete-button p-0"></i>
                    </b-button>
                </b-col>       
                <b-col cols="12" sm="6" class="pt-0 px-2 pb-0">
                    <validationProvider :name="`Rin ${index+1} Sex`" rules="required" v-slot="{ errors }">
                    <b-form-group 
                    :id="`input-group-${index}-1`" 
                    label="Sex:" 
                    :label-for="`input-${index}-1`"
                    :invalid-feedback="errors[0]"
                    :state="(errors.length == 0) ? true : false">
                        <b-form-select2
                        :id="`input-${index}-1`"
                        v-model="rinc.sex"
                        :options="opsSex"
                        required
                        variant="solid"
                        :state="(errors.length == 0 && rinc.sex != null) ? true : null"
                        ></b-form-select2>
                    </b-form-group>
                    </validationProvider>
                </b-col>
                <b-col cols="12" sm="6" class="pt-0 px-2 pb-0">
                    <validationProvider :name="`Rin ${index+1} Jumlah`" rules="required|min_value:1|max_value:9999999999" v-slot="{ errors }">
                    <b-form-group 
                    :id="`input-group-${index}-2`" 
                    label="Jumlah:" 
                    :label-for="`input-${index}-2`"
                    :invalid-feedback="errors[0]"
                    :state="(errors.length == 0) ? true : false">
                        <vue-number-input 
                        :attrs="{ id: `input-${index}-2`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                        v-model="rinc.jumlah"
                        :min="0"
                        :max="9999999999"
                        required
                        center
                        controls
                        ></vue-number-input>
                    </b-form-group>
                    </validationProvider>
                </b-col>
            </b-row>

            <b-row class="w-100 mx-0">
                <b-col cols="12" class="text-right">
                    <b-button @click="addRincian" variant="clear-primary" class="text-hover-primary">
                    <i class="flaticon2-plus-1 p-0"></i>
                    Add Saleable
                    </b-button>
                </b-col>
            </b-row>

            <validationProvider :name="`Hatchery Asal DOC`" rules="required" v-slot="{ errors, valid }">
              <b-form-group 
              :id="`input-group-26`" 
              :label-for="`input-26`"
              :invalid-feedback="errors[0]"
              :state="valid">
                <treeselect
                  :multiple="true"
                  required
                  :options="opsAsalDOC"
                  :normalizer="normalizer"
                  :flatten-search-results="true"
                  placeholder="Pilih Asal DOC (Hatchery)"
                  v-model="form.asal"
                />
              </b-form-group>
            </validationProvider>

          </b-form>
          </ValidationObserver>

          <div class="mt-3">
            <div v-if="sdata.action == 'perbaikan'" class="mt-3">
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'draft')" 
                  ref="kt_submit"
                  class="btn btn-info font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Simpan Draf
                </button>
                <button
                  v-if="buttonMenu == 'draf'" 
                  @click="onSubmit($event, 'ajukan')" 
                  ref="kt_submit"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Ajukan
                </button>
                <button
                  v-if="buttonMenu == 'ajukan'" 
                  @click="onBackDraf" 
                  ref="kt_submit_draf"
                  class="btn btn-primary font-weight-bold mr-2"
                >
                  <i class="la la-eye"></i>
                  Kembalikan Draf
                </button>
                <button
                  @click="closeDialog()"
                  class="btn btn-clean font-weight-bold"
                >
                  <i class="la la-recycle"></i>
                  Close
                </button>
            </div>
            <div v-else class="mt-3">
              <button 
                @click="onSubmit($event, 'ajukan')" 
                ref="kt_submit"
                class="btn btn-primary font-weight-bold"
              >
                <i class="la la-eye"></i>
                Simpan
              </button>
              &nbsp;
              <button
                @click="closeDialog()"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Close
              </button>
            </div>
          </div>
        </perfect-scrollbar>  
        </div>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DistribusiService from "@/core/services/api/nasional/distribusi.service";
import PerusahaanService from "@/core/services/api/master/perusahaan.service";
import MasterService from "@/core/services/api/master/master.service";
import ErrorService from "@/core/services/error.service";

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "unggas-nasional-data-distribusi-send-doc-edit",
  props: {
      sdata: {
          required: true
      }
  },
  components: {
    Treeselect
  },
  data() {
    return {
      loading: false,
      form: {
        _id: this.sdata._id || null,
        _hid: null,
        kategori: null,
        strain: null,
        tipe: null,
        rincian: [{
          sex: null,
          jumlah: 0
        }],
        asal: [],
        status: 15,
        type: 'ajukan',
      },
      buttonMenu: 'draf',
      show: true,
      opsKategoriDistribusi: [{
        value: null,
        text: 'Silahkan pilih Int/Eks'
      }],
      opsStrain: [{
        value: null,
        text: 'Silahkan pilih Strain'
      }],
      opsSex: [{
        value: null,
        text: 'Silahkan pilih Sex'
      }],
      opsAsalDOC: [],
    }
  },
  computed: {
    cekSex () {
      const lookup = this.form.rincian.reduce((a, e) => {
        a[e.sex] = ++a[e.sex] || 0;
        return a;
      }, {});

      return this.opsSex.filter(e => lookup[e.value] && e.value !== null)
    },
  },
  methods: {
    addRincian(){
        this.form.rincian.push({
            sex: null,
            jumlah: 0
        })
    },
    deleteRincian(index){
        if (this.form.rincian.length > 1) {
            this.form.rincian.splice(index,1);
        }
        else {
            this.form.rincian = [{
                    sex: null,
                    jumlah: 0
                }
            ]
        }
    },
    closeDialog() {
        return this.$emit('close-modal', {status: true, refresh: false})
    },
    normalizer(node) {
      if (!node) return
      return {
        id: node.value,
        label: `${node.text}`,
        children: node.childrens,
      }
    },
    getTernakSex(val) {
      if (!val) {
        return
      }
      MasterService.getTernakSex({tipe: val})
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsSex = [{
                  value: null,
                  text: "Silahkan pilih Sex"
              }]
              this.loading = false
              return;
          }

          this.opsSex = res.data.data
          this.opsSex.push({
              value: null,
              text: "Silahkan pilih Sex"
          })
          this.loading = false
      })
      .catch((err) => {
        this.opsSex = [{
            value: null,
            text: "Silahkan pilih Sex"
        }]
        this.loading = false
        ErrorService.status(err)
      })
    },
    getTernakStrain() {
      MasterService.getTernakStrain()
      .then((res) => {
          if (!res.data.status) {
              ErrorService.message(res.data)
              this.opsStrain = [{
                  value: null,
                  text: "Tidak ada Strain"
              }]
              return;
          }

          this.opsStrain = res.data.data
          this.opsStrain.push({
              value: null,
              text: "Silahkan pilih Strain"
          })
      })
      .catch((err) => {
        ErrorService.status(err)
        this.opsStrain = [{
            value: null,
            text: "Tidak ada Strain"
        }]
      })
    },
    getKategoriDistribusi() {
      MasterService.getKategoriDistribusi()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsKategoriDistribusi = [{
                  value: null,
                  text: "Tidak ada Int/Eks"
                }]
                return;
            }

            this.opsKategoriDistribusi = res.data.data
            this.opsKategoriDistribusi.push({
              value: null,
              text: "Silahkan pilih Int/Eks"
            })
        })
        .catch((err) => {
          ErrorService.status(err)
          this.opsKategoriDistribusi = [{
            value: null,
            text: "Tidak ada Int/Eks"
          }]
        })
    },
    getHatchery() {
      PerusahaanService.getPerusahaanHatchery()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsAsalDOC = []
                this.loading = false
                return;
            }

            this.opsAsalDOC = res.data.data
            this.loading = false
        })
        .catch((err) => {
          this.opsAsalDOC = []
          this.loading = false
          return ErrorService.status(err)
        })
    },
    getData () {
      const params = {
        distribusi: this.sdata._id,
      }

      let action = "getSendDocEdit"
      if(this.sdata.action == 'perbaikan') {
        action = "getSendDocPerbaikan"
        params._id = this.sdata._id
      }
      DistribusiService[action](params)
      .then((res) => {
        if (res.data.status) {
          this.form._id = res.data.data._id 
          this.form._hid = res.data.data._hid 
          this.form.kategori = res.data.data.kategori 
          this.form.strain = res.data.data.strain 
          this.form.tipe = res.data.data.tipe 
          this.form.rincian = res.data.data.rincian 
          this.form.asal = res.data.data.asal 
          if(this.sdata.action == 'perbaikan') {
            this.form.distribusi_ajuan = res.data.data._distribusi
            this.form.status = res.data.data._ids
            if(this.form.status == 170) {
              this.buttonMenu = 'ajukan'
            }
          }
          this.getTernakSex(this.form.tipe)
          return;
        }
        ErrorService.message(res.data)
        this.closeDialog()
      })
      .catch((err) => {
        ErrorService.status(err)
        this.closeDialog()
      })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt, ajukan) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        if (this.form.rincian.length == 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Rincian data tidak lengkap',
            })
            return;
        }

        if (this.cekSex.length > 0) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Double input sex',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            let action = "updateSendDoc"
            if(this.sdata.action == 'perbaikan') {
              if(ajukan == 'draf') {
                this.form.status = 15
                this.form.type = 'ajukan'
              }
              if(ajukan == 'ajukan') {
                this.form.status = 170
                this.form.type = 'ajukan'
              }
              action = 'updateSendDocPerbaikan'
            }

            DistribusiService[action](this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.onReset()
                      this.$emit('close-modal', {status: true, refresh: true})
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      this.form.kategori = null
      this.form._hid = null
      this.form._id = this.sdata._id || null
      this.form.tipe = null
      this.form.strain = null
      this.form.status = 15
      this.form.rincian = [{
        sex: null,
        jumlah: 0
      }]
      this.form.asal = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    onBackDraf (evt) {
        evt.preventDefault()
        // set spinner to submit button
        const submitButton = this.$refs["kt_submit_draf"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        DistribusiService.updateSendDocPerbaikan({...this.form, status: 15, type: 'draf'})
        .then((res) => {
            if (res.data.status) { 
              this.form.status = 15
              this.form.type = 'draf'   
              this.buttonMenu = 'draf'   
            }
            ErrorService.message(res.data)
            this.removeSpinner(submitButton)
        })
        .catch((err) => {
            this.removeSpinner(submitButton)
            return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Distribusi DOC" }]);
    this.getTernakStrain()
    this.getKategoriDistribusi()
    this.getHatchery()
    this.getData();
  },
};
</script>
