<template>
  <div id="unggas-nasional-data-distribusi-doc-riwayat" data-app>

    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
          
        <div class="card-title">
            <button 
                @click="$router.push(`/unggas-nasional/distribusi/${idH}`)" 
                type="button" 
                class="btn btn-link-primary font-weight-bolder mr-5 font-size-lg"
                v-b-tooltip.hover title="List Distribusi DOC"
            >
                <div class="d-flex">
                    <span class="svg-icon svg-icon-lg">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Navigation/Angle-double-left.svg" />
                        <!--end::Svg Icon-->
                    </span>
                    <span class="d-none d-sm-block">Kembali</span>
                </div>
            </button>
            <div class="align-items-start flex-column">
				<h3 class="card-label font-weight-bolder text-dark">Riwayat Distribusi</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Data Distribusi Ayam Ras</span>
            </div>
        </div>
        <div class="card-toolbar">
            <button @click="dialogFilter = true" class="btn btn-light-success btn-sm rounded font-weight-bolder font-size-sm px-4 p-2">
                <span class="svg-icon svg-icon-sm">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Text/Filter.svg"/>
                    <!--end::Svg Icon-->
                </span>          
                Filter
            </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
          <!--begin::Table-->
          <div>
              <table class="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                      <tr class="text-uppercase">
                          <th class="pl-7 cursor-pointer">
                              <span>Distribusi</span>
                          </th>
                          <th class="pl-7 cursor-pointer d-none d-sm-table-cell">
                              <span>Jumlah</span>
                          </th>
                          <th class="pl-7 cursor-pointer d-none d-md-table-cell">
                              <span>Status</span>
                          </th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody v-if="!isBusy">
                      <tr v-for="(val, key) in items" :key="key">
                          <td class="pl-0 py-8">
                              <div class="d-flex align-items-center">
                                  <div class="symbol symbol-50 flex-shrink-0 mr-4">
                                      <div class="symbol-label">
                                        <span v-if="val.peringatan" v-b-tooltip.hover :title="val.peringatan" :id="`peringatan-${key}`" class="cursor-pointer svg-icon svg-icon-lg svg-icon-danger mr-2 m-0">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                                            <!--end::Svg Icon-->
                                        </span> 
                                      </div>
                                  </div>

                                  <div>
                                      <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ val.customer }}</a>
                                      <span class="text-muted font-weight-bold d-block">Prov. {{ val.provinsi.uraian }} {{ val.kabupaten.uraian }} Kec. {{ val.kecamatan.uraian }} Ds. {{ val.desa.uraian }}</span>
                                  </div>
                              </div>
                          </td>
                          <td class="d-none d-sm-table-cell">
                              <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                  {{ val.rincian_sum_jumlah | nominal }}
                              </span>
                              <span class="text-muted font-weight-bold">
                                  ekor
                              </span>
                          </td>
                          <td class="d-none d-md-table-cell">
                              <b-badge :variant="val.sts.color">{{ val.sts.uraian }}</b-badge>
                          </td>
                          <td class="text-right pr-0">
                            <b-dropdown size="sm" variant="hover-primary" class="btn-light rounded">
                                <template v-slot:button-content>
                                    <span class="svg-icon svg-icon-md svg-icon-primary">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Code/Compiling.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="d-none d-sm-inline">Action</span>
                                </template>                                        
                                <b-dropdown-item @click="$router.push(`/unggas-nasional/distribusi/${idH}/${val._id}?link=history&pq=${currentPage}`)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-sm svg-icon-danger p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/poultry-farm/kandang.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Detail</span>
                                </b-dropdown-item>  
                                <b-dropdown-divider v-if="val.status == 170 || val.status == 200"></b-dropdown-divider>                            
                                <b-dropdown-item v-if="val.status == 170" @click="openDialogForm('FormEditSendDoc', val)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-primary p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="val.status == 170" @click="deletePopupData(val)">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-primary p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Trash.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Delete</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="val.status == 200">
                                    <span class="align-self-center">Perbaikan</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="val.status == 200" @click="openDialogForm('FormPerbaikan', {_id: val._id, action: 'perbaikan', kategori: 'send-doc'})">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-primary p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Ajukan</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="val.status == 200 && val.last_versioning_count" @click="openDialogForm('FormPerbaikan', {_id: val._id, action: 'pembatalan', kategori: 'send-doc'})">
                                    <span class="bg-light rounded cursor-pointer svg-icon svg-icon-primary p-2 mr-2 m-0">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="media/svg/icons/Home/Trash.svg"/>
                                        <!--end::Svg Icon-->
                                    </span>
                                    <span class="align-self-center">Batalkan</span>
                                </b-dropdown-item>
                            </b-dropdown>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-else>
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                      
                      <tr>
                          <td class="p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-sm-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                          <td class="d-none d-md-table-cell p-3">
                              <ContentLoader height="20"></ContentLoader>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <!--end::Table-->
      </div>
      <!--end::Body-->
      <div class="card-footer">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage" align="center"></b-pagination>
      </div>

  </div>

    <v-dialog v-model="dialogFilter" persistent scrollable max-width="500px">
        <v-card class="px-4 h-100">
            <v-card-title class="headline">Filter Data Setting HE</v-card-title>
        
            <perfect-scrollbar
            class="scroll"
            style="max-height: 89%; position: relative;"
            >
                <ValidationObserver ref="formFilter">
                <b-form @submit="onSubmitFilter" v-if="show">
                    <v-card-text>
                        <div class="mb-7" role="group">
                        <label for="input-filter-1">Tanggal Setting HE:</label>
                        <b-button id="input-filter-1" class="form-control form-control-solid text-left" variant="outline-secondary" @click="modalDate = true">{{ showDate }}</b-button>
                        </div>

                        <validationProvider name="Status" v-slot="{ errors, valid }">
                            <b-form-group
                                id="input-filter-group-2" 
                                label="Status:" 
                                label-for="input-filter-2"
                                :invalid-feedback="errors[0]"
                                :state="valid">
                                <b-form-select2
                                id="input-filter-2"
                                v-model="filter.status"
                                :options="opsStatus"
                                required
                                variant="solid"
                                :state="valid"
                                ></b-form-select2>
                            </b-form-group>
                        </validationProvider>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <button 
                        @click="onSubmitFilter" 
                        ref="kt_submit_form_filter"
                        class="btn btn-primary font-weight-bold m-3">
                        <i class="la la-save"></i>
                        Filter
                        </button>

                        <button
                        class="btn btn-clean font-weight-bold m-3"
                        type="button"
                        @click="onResetFilter()">
                        <i class="la la-recycle"></i>
                        Reset
                        </button>

                        <button
                        class="btn btn-clean font-weight-bold m-3"
                        type="button"
                        @click="dialogFilter = false">
                        <i class="la la-close"></i>
                        Close
                        </button>
                    </v-card-actions>
                
                </b-form>
                </ValidationObserver>
            </perfect-scrollbar>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
      <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

    <v-dialog
    ref="dialogDate"
    v-model="modalDate"
    persistent
    width="290px"
    >
        <v-date-picker
        v-model="dates"
        scrollable
        range
        color="bg-primary"
        header-color="bg-primary"
        >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="modalDate = false"
        >
            Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="setDate()"
        >
            OK
        </v-btn>
        </v-date-picker>
    </v-dialog>

    <v-dialog
    v-model="dialogDelete"
    max-width="300">
        <v-card>
            <v-card-title class="headline">Hapus data</v-card-title>

            <v-card-text>
                Apakah anda yakin akan menghapus data <b>{{(dataHapus ? `Distribusi ke ${dataHapus.customer}` : '')}}</b> ? data yang dihapus tidak dapat dikembalikan lagi
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <b-button
            variant="link-success"
            class="m-3"
            @click="dialogDelete = false">
                BATAL
            </b-button>

            <b-button
            variant="link-danger"
            class="m-3"
            @click="onDelete">
                HAPUS
            </b-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DistribusiService from "@/core/services/api/nasional/distribusi.service";
import MasterService from "@/core/services/api/master/master.service";
import ErrorService from "@/core/services/error.service";

import FormEditSendDoc from "@/view/pages/nasional/distribusi/form/send-doc/_Edit.vue";
import FormPerbaikan from "@/view/pages/nasional/distribusi/form/_AjukanPerbaikan.vue";

import VueContext from 'vue-context';
import 'vue-context/src/sass/vue-context.scss';

export default {
  name: "unggas-nasional-data-distribusi-doc-riwayat",
  props: {
    idH: {
      required: true
    }
  },
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 8,
      isBusy: true,
      modalDate: false,
      dialogDetail: false,
      curComponent: null,
      sdataForm: null,
      dialogFilter: false,
      show: true,
      dates: [],
      filter: {
          tgl_awal: null,
          tgl_akhir: null,
          status: null
      },
      items: [],
      dialogDelete: false,
      dataHapus: null,

      opsStatus: [{
          value: null,
          text: 'Silahkan pilih Status',
      }],
    }
  },  
  components: {
    VueContext,
    ContentLoader,
    FormEditSendDoc,
    FormPerbaikan,
  },    
  computed: {
    ...mapGetters(["currentUser"]),
    showDate() {
        return (this.filter.tgl_awal || this.filter.tgl_akhir ? `${this.filter.tgl_awal} ~ ${this.filter.tgl_akhir}` : 'Silahkan klik untuk pilih tanggal')
    },
  },
  methods: {
    setDate() {
        if (this.dates.length < 1) {
            ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan pilih tanggal terlebih dahulu',
            })
            return;
        }
        if (this.dates.length < 2) {
            this.dates.push(this.dates[0])
        }
        this.dates.sort()
        this.filter.tgl_awal = this.dates[0]
        this.filter.tgl_akhir = this.dates[1]
        this.modalDate = false
        this.errorDate = false
    },
    openDialogForm(name, data) {
      this.curComponent = name
      this.sdataForm = data
      this.dialogDetail = true
    },
    closeDialogForm(res) {
      if (res.status) {
        if (res.refresh) {
            this.getData(this.currentPage)    
        }
        this.curComponent = null
        this.sdataForm = null
        this.dialogDetail = false
      }
    },
    changePage(val){
        if(this.currentPage == val) return
        this.isBusy = true
        this.items = []
        this.getData(val)
    },
    resetTable() {
        this.items = []
        this.totalRows = 0
        this.currentPage = 1
    },

    getStatus() {
        MasterService.getStatus()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsStatus = [{
                    value: null,
                    text: "Silahkan pilih Status"
                }]
                return;
            }

            this.opsStatus = res.data.data
            this.opsStatus.push({
                value: null,
                text: "Silahkan pilih Status"
            })
        })
        .catch((err) => {
          this.opsStatus = [{
            value: null,
            text: "Tidak ada Status"
          }]
          ErrorService.status(err)
        })
    },
    getData (page = 1, perPage = this.perPage, search = this.filter) {
        this.isBusy = true
        DistribusiService.getRiwayatSendDoc({hatchery:this.idH, page, per_page: perPage, ...search})
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isBusy = false
                return;
            }
            this.items = res.data.data.data
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total
            this.isBusy = false
        })
        .catch((err) => {
            this.resetTable()
            this.isBusy = false
            return ErrorService.status(err)
        })
    },
    deletePopupData(val) {
        this.dataHapus = val
        this.dialogDelete = true
    },
    onDelete () {
        if (! this.dataHapus) {
            return
        }
        DistribusiService.deleteSendDoc({ _hid: this.idH, distribusi: this.dataHapus._id })
        .then((res) => {
            if (res.data.status) { 
            this.getData(this.currentPage)              
            }
            ErrorService.message(res.data)
            this.dialogDelete = false
            this.dataHapus = null
        })
        .catch((err) => {
            this.dialogDelete = false
            this.dataHapus = null
            return ErrorService.status(err)
        })
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmitFilter(evt) {
        evt.preventDefault()
        this.$refs.formFilter.validate().then(success => {
            if (!success) {
                ErrorService.message({
                    action: 'Peringatan',
                    message: 'Silahkan lengkapi form data terlebih dahulu',
                })
                return;
            }

            // set spinner to submit button
            const submitButton = this.$refs["kt_submit_form_filter"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            // dummy delay
            setTimeout(() => {
                this.dialogFilter = false
                this.getData(1)

                this.removeSpinner(submitButton)
            }, 800);
        })
    },
    onResetFilter () {
        this.filter.tgl_awal = null
        this.filter.tgl_akhir = null
        this.filter.status = null
        this.dates = []

        this.show = false
        this.$nextTick(() => {
            this.show = true
        })
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Distribusi DOC" }]);
    if (!this.currentUser.menu.nasional_distribusi) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
    this.getStatus()
  },
};
</script>

<style lang="scss">
#unggas-nasional-data-distribusi-doc-riwayat {
  .b-table-stacked-sm {
    tbody {
      tr { 
        [data-label] > div {
          width: calc(100% - 25%);
        }
        [data-label]::before {
          width: 25% !important;
        }
      }
    }
  }
}
</style>